import React from "react";

export default function Footer() {
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center">
          <div className="px-4 flex flex-col justify-items-center">
            <h4 className="text-3xl font-semibold">
              Let's keep in touch!
            </h4>
            <div className="mt-6 flex justify-center">
              <button
                className="bg-white hover:bg-gray-200 hover:shadow-lg duration-200 text-blue-500 shadow-lg font-normal h-12 w-12  rounded-full items-center justify-center align-center outline-none focus:outline-none mr-2"
                onClick={() => { window.open("https://www.linkedin.com/in/seva-oparin/"); }}
                target="_blank"
                type="button"
              >
                <i className="fab fa-linkedin-in fa-2x"></i>
              </button>
              <button
                className="bg-white hover:bg-gray-200 hover:shadow-lg duration-200 text-gray-900 shadow-lg font-normal h-12 w-12  rounded-full items-center justify-center align-center outline-none focus:outline-none mr-2"
                onClick={() => { window.open("https://github.com/vsevolod-oparin/"); }}
                target="_blank"
                type="button"
              >
                <i className="fab fa-github fa-2x"></i>
              </button>

              <button
                className="bg-white hover:bg-gray-200 hover:shadow-lg duration-200 text-blue-400 shadow-lg font-normal h-12 w-12  rounded-full items-center justify-center align-center outline-none focus:outline-none mr-2"
                onClick={() => { window.open("https://t.me/MirLy"); }}
                target="_blank"
                type="button"
              >
                <i className="fab fa-telegram fa-2x"></i>
              </button>
              <button
                className="bg-white hover:bg-gray-200 hover:shadow-lg duration-200 text-gray-900 shadow-lg font-normal h-12 w-12  rounded-full items-center justify-center align-center outline-none focus:outline-none mr-2"
                onClick={() => { window.open("mailto:oparin.vsevolod@gmail.com"); }}
                target="_blank"
                type="button"
              >
                <i className="far fa-envelope fa-2x"></i>
              </button>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Powered by Tailwind Starter Kit / {" "}
              <a
                href="https://www.creative-tim.com"
                className="text-gray-600 hover:text-gray-900"
              >
                Creative Tim
              </a>.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
