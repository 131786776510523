import React from "react";

import Footer from "components/Footer.js";
import { Backform } from "views/Backform.js";


function PetCard(props) {
  let href = props.href;
  let activeShadow = props.href ? " hover:shadow-2xl" : ""
  let iconDivStyle = `${props.colors || ""} text-center inline-flex items-center justify-center w-10 h-10 rounded-full`;
  let pointer = props.href ? " cursor-pointer" : "";

  function handler(e) {
    if (href) {
      window.open(href, '_blank', 'noopener,noreferrer')
    }
  }
  return (
    <div className={"lg:pt-12 py-3 w-full px-4 text-center relative" + pointer} onClick={handler}>
      <div className={"relative flex flex-col min-w-0 break-words bg-white w-full h-full mb-2 shadow-lg rounded-lg" + activeShadow}>
        <div className="px-4 pt-5 flex-auto">
          <div className="flex items-start">
            <div className={iconDivStyle}>
              <i className={props.iconStyle}></i>
            </div>
            <div className="mx-4"/>
            <h6 className="text-xl mt-1 font-semibold ">{props.title}</h6>
          </div>
          <p className="mt-4 mb-4 text-gray-600 text-left">
            {props.children}
          </p>
        </div>
      </div>
    </div>
  );
}

function TGCard(props) {
  return (
    <PetCard
      iconStyle="fab fa-telegram fa-3x"
      title={props.title}
      colors="bg-white text-blue-400"
      href={props.href || ""}
    >
      {props.children}
    </PetCard>
  );
}

function WebCard(props) {
  return (
    <PetCard
      iconStyle="fab fa-html5 fa-3x"
      title={props.title}
      colors="bg-white text-green-600"
      href={props.href || ""}
    >
      {props.children}
    </PetCard>
  );
}

function UdemyCard(props) {
  return (
    <PetCard
      iconStyle="udemy"
      title={props.title}
      href={props.href || ""}
    >
      {props.children}
    </PetCard>
  );
}

function Publication(props) {
  return (<div className="px-4 pt-5 ml-5 flex-auto flex">
    <div className="text-blue-400 bg-white text-center inline-flex items-begin justify-center w-10 h-10 shadow-lg rounded-full mr-5 ">
      <i className="fa fa-scroll  mt-3"></i>
    </div>
    <p className='text-left'>
      <span className='font-bold'>[{props.year}] {props.title}</span><br/>
      {props.authors};<br/>
      {props.place}
    </p>
  </div>);
}

export default function Landing() {
  let url = require("assets/img/office.jpg").default;

  return (
    <>
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "70vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage: `url('${url}')`
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="flex-initial flex-shrink" />
              <div className="flex-1 px-4 mr-10 text-center flex">
                <div className="px-6 flex-1">
                  <img
                    alt="..."
                    src={require("assets/img/me2.jpg").default}
                    className="shadow-lg rounded-full max-w-full mx-auto"
                    style={{ maxWidth: "240px" }}
                  />
                </div>
              </div>
              <div className="w-6/12 px-4 text-center flex-1">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl ml-8 mt-4">
                    Seva Oparin
                  </h1>
                  <p className="mt-4 ml-8 text-lg text-gray-300 text-left">
                    Research Scientist and Jack-Of-All-Trades in CS.
                    I can both read the math and design software.
                    Mainly interested in clouds and backend development.
                    Though I don't mind to make a web-interface.
                  </p>
                </div>
              </div>
              <div className="flex-initial flex-shrink" />

            </div>
          </div>

          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300">
          <div className="container mx-auto px-4 -mt-24">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-yellow-400 p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-black">
                      <i className="fab fa-aws fa-2x"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Cloud Computing</h6>
                    <p className="mt-2 mb-4 text-gray-600 text-left">
                      I used to work on FB Tupperware System. After some expereince with AWS EC2, I got interested in developing skills with larger scale software.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-blue-600">
                      <p className='font-sans text-xl font-bold'>f(x)</p>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Math Expertise
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      I have a PhD in Theoretical Computer Science, some expereince with mathematical optimization problems, and also passed Andrew Ng's course on ML.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-14 h-14 mb-5 shadow-lg rounded-full bg-green-600">
                      <i className="fas fa-route fa-2x"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Self-motivated and <br/>goal oriented
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      I can move on my own, find people I need in the company. I put achieving the goals over engineering. Also I can mentor others.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='flex flex-row mt-6'>
              <div className='flex-1' />
              <form mehtod="get" action={require("assets/cv.pdf").default} target="_blank">
              <button
                className='text-3xl text-white font-sans rounded-lg shadow-lg py-4 px-8 duration-200 bg-blue-600 hover:bg-blue-700'
              >
                Download CV
              </button>
              </form>
              <div className='flex-1' />
            </div>

            <div className='mt-20 mb-10 lg:mb-0 ml-5 flex justify-center'>
              <div className="text-red-600 bg-white text-center inline-flex items-center justify-center -mt-1 w-14 h-14 rounded-full">
                <i className="fas fa-industry fa-2x"></i>
              </div>
              <h3 className="text-3xl font-semibold ml-5 leading-normal">
                Industrial Experience
              </h3>
            </div>

            <div className="grid lg:grid-cols-2 grid-cols-1 place-content-stretch">
              <PetCard
                iconStyle="fab fa-facebook fa-3x"
                colors="bg-white text-blue-500"
                title="Facebook, Inc. - Research Scientist - 2016-2020"
              >
                I worked on end-to-end network data analysis. From building pipeline
                to manage data-intensive flows to writing mathematical formulas
                to detect anomalies.
              </PetCard>

              <PetCard
                iconStyle="jetbrains"
                title="JetBrains - Software Developer - 2010-2011"
              >
                I contributed to YouTrack, bugtracker developed within the walls
                of IntelliJ Labs a.k.a JetBrains. I worked both to backend
                and frontend parts.
              </PetCard>


            </div>

            <div className='mt-20 mb-10 lg:mb-0 ml-5 flex justify-center'>
              <div className="text-green-600 bg-white text-center inline-flex items-center justify-center -mt-1 w-14 h-14 rounded-full">
                <i className="fas fa-dog fa-2x"></i>
              </div>
              <h3 className="text-3xl font-semibold ml-5 leading-normal">
                Pet Projects
              </h3>
            </div>

            <div className="grid xl:grid-cols-2 md:grid-cols-2 grid-cols-1 mb-5 place-content-stretch">
              <TGCard title="Telegram QR Code Bot" href="https://t.me/qr_myid_bot">
                The bot to generate QR codes for telegram for instant contacts.
                Just start it to generate your own code.
              </TGCard>

              <TGCard title="Telegram TagTagBot" href="https://t.me/tagtag_bot">
                Reference Bot that helps people to subscribe on tags and then
                get notified once the tag is mentioned.
              </TGCard>

              <UdemyCard title="Udemy Course on Geometry" href="https://www.udemy.com/course/2d-geometry-for-game-development/">
                I developed two-hours animated course "2D Geometry for Game
                Development in Python" to help early beginners learn basics
                of geometry and programming in Python.
              </UdemyCard>

              <WebCard title="QuotterBack Service" href="https://www.quotterback.com">
                Service that automates quote extraction from Google Doc
                to Google Spreadhseet.
              </WebCard>
            </div>
          </div>
        </section>

        <section className="relative py-20 bg-white">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-col flex-wrap">
              <div className='mt-2 mb-10 lg:mb-0 ml-5 flex justify-center'>
                <div className="text-blue-600 bg-white text-center inline-flex items-center justify-center -mt-1 w-14 h-14 rounded-full">
                  <i className="fas fa-flask fa-2x"></i>
                </div>
                <h3 className="text-3xl font-semibold ml-5 leading-normal">
                  Publications
                </h3>
              </div>

              <div className="lg:py-12 py-3 w-10/12 px-4 text-center relative">
                <div className='flex justify-center w-full'>
                  <div className="relative flex flex-col min-w-0 break-words bg-gray-200 w-auto h-full mb-2 shadow-lg rounded-lg py-6 pr-6">
                    <Publication
                      title="Computational and Proof Complexity of Partial String Avoidability"
                      authors="Dmitry Itsykson, Alexander Okhotin, Vsevolod Oparin"
                      place="ACM Trans. Comput. Theory 13(1): 6:1-6:25 (2021)"
                      year="2021"
                    />

                    <Publication
                      title="Algorithmic and Hardness Results for the Hub Labeling Problem"
                      authors="Haris Angelidakis, Yury Makarychev, Vsevolod Oparin"
                      place="SODA 2017: 1442-1461"
                      year="2017"
                    />

                    <Publication
                      title="Tight Lower Bounds on the Resolution Complexity of Perfect Matching Principles"
                      authors="Dmitriy Itsykson, Vsevolod Oparin, Mikhail Slabodkin, Dmitriy Sokolov"
                      place="Fundamenta Informaticae 145 (2016) 1-14"
                      year="2016"
                    />

                    <Publication
                      title="Computational and Proof Complexity of Partial String Avoidability"
                      authors="Dmitry Itsykson, Alexander Okhotin, Vsevolod Oparin"
                      place="MFCS 2016: 51:1-51:13"
                      year="2016"
                    />

                    <Publication
                      title="Tight Upper Bound on Splitting by Linear Combinations for Pigeonhole Principle"
                      authors="Vsevolod Oparin"
                      place="SAT 2016: 77-84"
                      year="2016"
                    />

                    <Publication
                      title="Tree-like resolution complexity of two planar problems"
                      authors="Dmitry Itsykson, Anna Malova, Vsevolod Oparin, Dmitry Sokolov"
                      place="CoRR abs/1412.1124 (2014)"
                      year="2014"
                    />


                    <Publication
                      title="Graph Expansion, Tseitin Formulas and Resolution Proofs for CSP"
                      authors="Dmitry Itsykson, Vsevolod Oparin"
                      place="CSR 2013: 162-173"
                      year="2013"
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>




        <section className="pb-20 relative block bg-gray-900">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 py-12 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Looking for an interesting project
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">

                </p>
              </div>
            </div>
          </div>
        </section>
        <Backform />
      </main>
      <Footer />
    </>
  );
}
